export default function ForCustomers() {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21_7104)">
        <rect
          x="6.5"
          y="2"
          width="60"
          height="60"
          rx="16"
          fill="url(#paint0_linear_21_7104)"
        />
        <path
          d="M33.66 30.87C33.56 30.86 33.44 30.86 33.33 30.87C30.95 30.79 29.06 28.84 29.06 26.44C29.06 23.99 31.04 22 33.5 22C35.95 22 37.94 23.99 37.94 26.44C37.93 28.84 36.04 30.79 33.66 30.87Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.91 24C42.85 24 44.41 25.57 44.41 27.5C44.41 29.39 42.91 30.93 41.04 31C40.96 30.99 40.87 30.99 40.78 31"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.66 34.56C26.24 36.18 26.24 38.82 28.66 40.43C31.41 42.27 35.92 42.27 38.67 40.43C41.09 38.81 41.09 36.17 38.67 34.56C35.93 32.73 31.42 32.73 28.66 34.56Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.84 40C43.56 39.85 44.24 39.56 44.8 39.13C46.36 37.96 46.36 36.03 44.8 34.86C44.25 34.44 43.58 34.16 42.87 34"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_21_7104"
          x="0.5"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.945098 0 0 0 0 0.8 0 0 0 0 0.65098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_21_7104"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_21_7104"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_21_7104"
          x1="19.4545"
          y1="6.77273"
          x2="52.1818"
          y2="56.5455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F2D8A0" />
          <stop offset="1" stop-color="#FFBD31" />
        </linearGradient>
      </defs>
    </svg>
  );
}
