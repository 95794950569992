import { twMerge } from "tailwind-merge";

export default function WhiteDiv({
  children,
  className,
}: {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}) {
  return (
    <div
      dir="ltr"
      className={twMerge(`absolute z-10 rounded-xl bg-white shadow`, className)}
    >
      {children}
    </div>
  );
}
