import { useTranslation } from "react-i18next";
import TitleAndHeader from "~/components/TitleAndHeader";
import ReviewsList from "./ReviewsList";

export default function Reviews() {
  const { t } = useTranslation("home");

  return (
    <section className="cont min-w-full overflow-x-hidden py-9">
      <TitleAndHeader
        title={t("loved")}
        header={t("reviews")}
        className="mb-6"
        alignHeader
      />
      <ReviewsList />
    </section>
  );
}
