import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import ImgWithDashedBorder from "~/components/ImgWithDashedBorder";
import TitleAndHeader from "~/components/TitleAndHeader";
import DashedLine from "~/svgs/DashedLine";
import ShadedCircle from "~/svgs/ShadedCircle";

export default function Categories() {
  const { t } = useTranslation("home");
  const services = [
    { name: t("madeToMeasure"), img: "sourcing.png", to: "sourcing" },
    { name: t("readyToWear"), img: "design.png", to: "design" },
    { name: t("hauteCourture"), img: "production.png", to: "production" },
  ];

  return (
    <section
      className="relative flex min-w-full flex-col items-center gap-14 overflow-x-hidden bg-gray-50 py-10
    "
    >
      <TitleAndHeader
        title={t("makesyServices")}
        header={t("categories")}
        className="mb-10 max-lg:mb-0"
      />
      <div className="z-10 mb-16 flex flex-col items-center justify-evenly gap-10 self-stretch lg:flex-row lg:gap-0">
        {services.map((service) => (
          <Link
            key={service.name}
            to={`/services/${service.to}`}
            className="flex cursor-pointer flex-col items-center gap-5 transition-all hover:scale-110"
          >
            <ImgWithDashedBorder
              src={`/images/${service.img}`}
              alt={service.name}
              className=" h-44 w-44"
            />
            <p className="w-72 rounded-xl border border-dashed border-Primary-Magic-Mint-Mm-600 bg-Primary-Magic-Mint-Mm-50 p-6 text-center text-2xl font-bold text-Secondary-Dark-Blue-db-500">
              {service.name}
            </p>
          </Link>
        ))}
      </div>
      <DashedLine className="absolute left-1/2 top-48 hidden min-w-full -translate-x-1/2 scale-125 lg:block" />
    </section>
  );
}
