import { json, type LoaderFunctionArgs } from "@remix-run/node";
import HeroSection from "./HeroSection";
import Categories from "./Categories";
import Segments from "./OurPartners";
import Features from "./Features";
import Reviews from "./Reviews";
import Questions from "./Questions";
import Blog from "./Blog";
import i18next from "~/utils/localization/i18next.server";
import { getFAQs, getBlogs, getReviews } from "~/api/base.server";
import { BlogWebsitePage } from "~/types/Blog";

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(request);

  const reviews = await getReviews({ pagination: { limit: 20 } });

  const featuredFAQs = await getFAQs({
    pagination: { limit: 4 },
    filters: { featured: true },
  });

  const blogList = (
    await getBlogs({
      filters: {
        websitePage: {
          $contains: BlogWebsitePage.Home,
        },
      },
      pagination: { limit: 1 },
    })
  )?.data;

  const blog = blogList?.length ?? 0 > 0 ? blogList?.[0] : null;

  return json({ reviews, locale, featuredFAQs, blog });
}

export let handle = { i18n: "home" };

export default function Index() {
  return (
    <div className="">
      <HeroSection />
      <Categories />
      <Segments />
      <Features />
      <Blog />
      <Reviews />
      <Questions />
    </div>
  );
}
