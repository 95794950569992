export default function InstantChat() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0875 21.0375L23.575 24.9875C23.7 26.025 22.5875 26.75 21.7 26.2125L16.4625 23.1C15.8875 23.1 15.325 23.0625 14.775 22.9875C15.7 21.9 16.25 20.525 16.25 19.0375C16.25 15.4875 13.175 12.6125 9.37499 12.6125C7.92499 12.6125 6.5875 13.025 5.475 13.75C5.4375 13.4375 5.42499 13.125 5.42499 12.8C5.42499 7.11249 10.3625 2.5 16.4625 2.5C22.5625 2.5 27.5 7.11249 27.5 12.8C27.5 16.175 25.7625 19.1625 23.0875 21.0375Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.25 19.0375C16.25 20.525 15.7 21.9 14.775 22.9875C13.5375 24.4875 11.575 25.45 9.375 25.45L6.1125 27.3875C5.5625 27.725 4.8625 27.2625 4.9375 26.625L5.25 24.1625C3.575 23 2.5 21.1375 2.5 19.0375C2.5 16.8375 3.67501 14.9 5.47501 13.75C6.58751 13.025 7.925 12.6125 9.375 12.6125C13.175 12.6125 16.25 15.4875 16.25 19.0375Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
