import { ChevronLeft, ChevronRight } from "lucide-react";
import CustomArrow from "~/components/CustomArrow";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useLoaderData } from "@remix-run/react";
import { handle, loader } from "./route";
import { cn } from "~/utils/helper";
import { useTranslation } from "react-i18next";
import ReviewCard from "~/components/ReviewCard";

export default function ReviewsList() {
  const { reviews, locale } = useLoaderData<typeof loader>();
  const { t } = useTranslation(handle.i18n);

  if (!reviews || !reviews.length) return <p>{t("no-reviews")}</p>;

  return (
    <div className="flex max-w-full items-center">
      <CustomArrow
        svg={<ChevronLeft />}
        className={cn("mt-16 bg-Secondary-Dark-Blue-db-500 text-white", {
          "arrow-left ": locale === "en",
          "arrow-right ": locale === "ar",
        })}
      />
      <div className="max-w-[calc(100%-4.5rem)] px-3 lg:px-14" dir="ltr">
        <Swiper
          modules={[Navigation]}
          spaceBetween={15}
          slidesPerView={3}
          initialSlide={1}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            1062: {
              slidesPerView: 3,
            },
          }}
          navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
          className="min-h-[420px] min-w-full"
          wrapperClass="flex items-center"
        >
          <SwiperSlide className="max-lg:!hidden"></SwiperSlide>
          {reviews?.map((review, i) => (
            <SwiperSlide key={i}>
              <ReviewCard key={i} review={review} locale={locale} />
            </SwiperSlide>
          ))}
          <SwiperSlide className="max-lg:!hidden"></SwiperSlide>
        </Swiper>
      </div>
      <CustomArrow
        svg={<ChevronRight />}
        className={cn("mt-16 bg-Secondary-Dark-Blue-db-500 text-white", {
          "arrow-right ": locale === "en",
          "arrow-left ": locale === "ar",
        })}
      />
    </div>
  );
}
