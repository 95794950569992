export default function ForGarmetProducers() {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21_7085)">
        <rect
          x="6.5"
          y="2"
          width="60"
          height="60"
          rx="16"
          fill="url(#paint0_linear_21_7085)"
        />
        <path
          d="M36.4996 44.8571C33.9567 44.8571 31.4709 44.1031 29.3566 42.6903C27.2422 41.2775 25.5943 39.2695 24.6212 36.9202C23.6481 34.5708 23.3934 31.9857 23.8895 29.4917C24.3856 26.9976 25.6102 24.7067 27.4083 22.9086C29.2064 21.1105 31.4973 19.886 33.9913 19.3899C36.4854 18.8938 39.0705 19.1484 41.4198 20.1215C43.7692 21.0946 45.7772 22.7426 47.19 24.8569C48.6027 26.9713 49.3568 29.4571 49.3568 32C49.353 35.4087 47.9972 38.6768 45.5868 41.0871C43.1764 43.4975 39.9084 44.8533 36.4996 44.8571ZM36.4996 20.1714C34.1602 20.1714 31.8732 20.8651 29.928 22.1649C27.9828 23.4646 26.4667 25.312 25.5715 27.4734C24.6762 29.6348 24.4419 32.0131 24.8983 34.3076C25.3547 36.6021 26.4813 38.7098 28.1356 40.364C29.7898 42.0183 31.8975 43.1448 34.192 43.6013C36.4865 44.0577 38.8648 43.8234 41.0262 42.9281C43.1876 42.0329 45.035 40.5168 46.3347 38.5716C47.6345 36.6264 48.3282 34.3394 48.3282 32C48.3247 28.8639 47.0773 25.8573 44.8598 23.6398C42.6423 21.4223 39.6357 20.1749 36.4996 20.1714Z"
          fill="white"
        />
        <path
          d="M36.5 41.1918C34.682 41.1919 32.9048 40.6529 31.3932 39.643C29.8815 38.633 28.7033 37.1975 28.0075 35.5179C27.3117 33.8383 27.1296 31.9902 27.4842 30.2071C27.8388 28.424 28.7142 26.7861 29.9997 25.5006C31.2852 24.215 32.923 23.3395 34.7061 22.9848C36.4891 22.6301 38.3373 22.8121 40.0169 23.5078C41.6965 24.2035 43.1321 25.3816 44.1422 26.8932C45.1522 28.4048 45.6913 30.182 45.6913 32C45.6886 34.4369 44.7194 36.7732 42.9963 38.4964C41.2732 40.2196 38.9369 41.189 36.5 41.1918ZM36.5 23.8367C34.8854 23.8366 33.3071 24.3153 31.9646 25.2122C30.6221 26.1092 29.5757 27.3841 28.9578 28.8757C28.3398 30.3673 28.1781 32.0087 28.493 33.5923C28.8079 35.1758 29.5854 36.6304 30.727 37.7721C31.8686 38.9138 33.3232 39.6913 34.9067 40.0064C36.4903 40.3214 38.1316 40.1598 39.6233 39.5419C41.115 38.9241 42.3899 37.8778 43.287 36.5353C44.184 35.1929 44.6627 33.6146 44.6627 32C44.6603 29.8358 43.7995 27.761 42.2693 26.2306C40.739 24.7002 38.6642 23.8393 36.5 23.8367Z"
          fill="white"
        />
        <path
          d="M39.9644 33.9615C39.5765 33.9615 39.1972 33.8464 38.8747 33.6309C38.5521 33.4154 38.3007 33.109 38.1522 32.7506C38.0038 32.3922 37.9649 31.9978 38.0406 31.6173C38.1163 31.2368 38.3031 30.8873 38.5774 30.613C38.8518 30.3387 39.2013 30.1519 39.5817 30.0762C39.9622 30.0005 40.3566 30.0394 40.715 30.1878C41.0735 30.3363 41.3798 30.5877 41.5953 30.9103C41.8109 31.2328 41.9259 31.6121 41.9259 32C41.9254 32.5201 41.7185 33.0186 41.3508 33.3864C40.9831 33.7541 40.4845 33.9609 39.9644 33.9615ZM39.9644 31.0671C39.7799 31.0671 39.5995 31.1218 39.4461 31.2243C39.2927 31.3268 39.1731 31.4725 39.1025 31.643C39.0319 31.8135 39.0134 32.001 39.0494 32.182C39.0854 32.363 39.1743 32.5292 39.3047 32.6597C39.4352 32.7901 39.6014 32.879 39.7824 32.915C39.9634 32.951 40.151 32.9325 40.3214 32.8619C40.4919 32.7913 40.6376 32.6717 40.7401 32.5183C40.8426 32.3649 40.8973 32.1845 40.8973 32C40.8971 31.7527 40.7987 31.5155 40.6238 31.3406C40.4489 31.1657 40.2118 31.0674 39.9644 31.0671Z"
          fill="white"
        />
        <path
          d="M33.0353 33.9615C32.6473 33.9615 32.2681 33.8464 31.9455 33.6309C31.6229 33.4154 31.3715 33.109 31.2231 32.7506C31.0746 32.3922 31.0358 31.9978 31.1115 31.6173C31.1871 31.2368 31.374 30.8873 31.6483 30.613C31.9226 30.3387 32.2721 30.1519 32.6526 30.0762C33.0331 30.0005 33.4275 30.0394 33.7859 30.1878C34.1443 30.3363 34.4506 30.5877 34.6662 30.9103C34.8817 31.2328 34.9967 31.6121 34.9967 32C34.9962 32.5201 34.7894 33.0186 34.4216 33.3864C34.0539 33.7541 33.5553 33.9609 33.0353 33.9615ZM33.0353 31.0671C32.8507 31.0671 32.6704 31.1218 32.517 31.2243C32.3635 31.3268 32.244 31.4725 32.1734 31.643C32.1027 31.8135 32.0843 32.001 32.1203 32.182C32.1563 32.363 32.2451 32.5292 32.3756 32.6597C32.5061 32.7901 32.6723 32.879 32.8533 32.915C33.0342 32.951 33.2218 32.9325 33.3923 32.8619C33.5627 32.7913 33.7084 32.6717 33.8109 32.5183C33.9135 32.3649 33.9682 32.1845 33.9682 32C33.9679 31.7527 33.8695 31.5155 33.6946 31.3406C33.5197 31.1657 33.2826 31.0674 33.0353 31.0671Z"
          fill="white"
        />
        <path
          d="M39.965 32.5143H33.0355C32.8991 32.5143 32.7683 32.4601 32.6718 32.3637C32.5754 32.2672 32.5212 32.1364 32.5212 32C32.5212 31.8636 32.5754 31.7328 32.6718 31.6363C32.7683 31.5399 32.8991 31.4857 33.0355 31.4857H39.965C40.1014 31.4857 40.2322 31.5399 40.3286 31.6363C40.4251 31.7328 40.4792 31.8636 40.4792 32C40.4792 32.1364 40.4251 32.2672 40.3286 32.3637C40.2322 32.4601 40.1014 32.5143 39.965 32.5143Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_21_7085"
          x="0.5"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.588235 0 0 0 0 0.588235 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_21_7085"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_21_7085"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_21_7085"
          x1="19.4545"
          y1="6.77273"
          x2="52.1818"
          y2="56.5455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F2A6B5" />
          <stop offset="1" stop-color="#FB6481" />
        </linearGradient>
      </defs>
    </svg>
  );
}
