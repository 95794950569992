import HeroIllustration from "~/svgs/home/HeroIllustration";
import WhyUseMakesy from "./WhyUseWearby";
import { useTranslation } from "react-i18next";
import HeadingWithDescription from "~/components/HeadingWithDescription";

export default function HeroSection() {
  let { t } = useTranslation("home");

  return (
    <section className="overflow-hidden">
      <div className="bg-Primary-Magic-Mint-Mm-100 px-6 py-2 text-center text-Secondary-Dark-Blue-db-500 max-md:text-sm">
        {t("ship anywhere")}
      </div>
      <div>
        <div className="bg-Primary-Magic-Mint-Mm-50 lg:-mb-10">
          <div
            className="cont hero-section relative flex min-w-full flex-col items-center justify-start gap-10
        pt-16 lg:flex-row lg:justify-between lg:gap-0
        "
          >
            <HeadingWithDescription
              heading={t("garments customization")}
              description={t("hero text")}
              className="flex-[5] lg:-mt-[15%] lg:max-w-[44rem] xl:min-w-[585px]"
            />
            <div className="flex flex-[4] justify-center lg:justify-end">
              <HeroIllustration className="lg:w-none h-auto max-h-[92vw] max-lg:w-[85%] lg:max-h-[630px]" />
            </div>
          </div>
        </div>
        <WhyUseMakesy />
      </div>
    </section>
  );
}
