import { useTranslation } from "react-i18next";
import TitleAndHeader from "~/components/TitleAndHeader";
import BlogSection from "~/components/BlogSection";
import { RightIllustration } from "~/svgs/home/VideoIllustrations";
import { useLoaderData } from "@remix-run/react";
import { loader } from "./route";

export default function Blog() {
  const { blog, locale } = useLoaderData<typeof loader>();

  return (
    <BlogSection
      className="justify-between overflow-x-hidden py-28"
      locale={locale}
      blog={blog}
    />
  );
}
