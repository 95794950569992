import { ChevronLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import Order, { OrderAr } from "~/svgs/common/Order";
import OrderStatus from "~/svgs/common/OrderStatus";
import { cn } from "~/utils/helper";

export default function MainIllustration({
  className,
  locale,
}: {
  className?: string;
  locale: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={cn("relative", className)}>
      <div className="absolute -left-[26%] top-[60px] flex h-[56px] w-[274px] flex-row-reverse items-center gap-4 rounded-lg bg-white px-3 py-1 shadow max-md:left-1/2 max-md:-translate-x-[calc(50%-50px)]">
        <ChevronLeft size={24} className="ltr:rotate-180" />
        <div className="flex flex-1 flex-col">
          <span className="text-[11px] font-bold text-Secondary-Dark-Blue-db-500">
            {t("orderId")} #68734
          </span>
          <p className="text-sm ">
            {t("orderStatus")}:{" "}
            <span className="font-semibold text-Secondary-Crayola-Space-Cs-500">
              {t("received")}
            </span>
          </p>
        </div>
      </div>
      <OrderStatus className="absolute left-[9%] top-[130px] w-[320px] max-md:left-1/2 max-md:-translate-x-[calc(50%+30px)]" />
      {locale === "ar" ? (
        <OrderAr className="absolute -left-[132px] top-[250px] max-md:left-1/2 max-md:-translate-x-1/2" />
      ) : (
        <Order className="absolute -left-[132px] top-[250px] max-md:left-1/2 max-md:-translate-x-1/2" />
      )}
      <div className="absolute -bottom-[10px] left-[150px] flex h-[105px] w-[245px] flex-row-reverse items-end gap-2 rounded-2xl bg-white p-3 shadow-lg max-md:left-1/2 max-md:-translate-x-[calc(50%+60px)]">
        <div className="flex flex-col items-end gap-1 rounded-t-xl rounded-br-xl bg-Light-grey-Lg-50 p-2">
          <p className=" text-[#0A1454] md:text-sm">{t("thanksForOrder")}</p>
          <div className="flex items-center gap-2 text-[9px] font-light text-Secondary-Crayola-Space-Cs-200 md:text-xs">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.946 9.54669C11.8609 9.62926 11.7971 9.73122 11.7601 9.84385C11.723 9.95648 11.7138 10.0764 11.7333 10.1934L12.326 13.4734C12.3504 13.6095 12.3351 13.7498 12.2819 13.8775C12.2287 14.0052 12.1399 14.1148 12.026 14.1934C11.9142 14.2749 11.7817 14.3232 11.6437 14.3326C11.5057 14.3421 11.3678 14.3123 11.246 14.2467L8.2933 12.7067C8.19042 12.6522 8.07633 12.6223 7.95997 12.6194H7.7793C7.71624 12.6286 7.6553 12.6489 7.5993 12.6794L4.64597 14.2267C4.49982 14.3003 4.3342 14.3259 4.17264 14.3C3.98282 14.264 3.81459 14.1552 3.70376 13.9969C3.59294 13.8387 3.54828 13.6434 3.5793 13.4527L4.17264 10.1727C4.19203 10.0548 4.18284 9.93402 4.14583 9.82044C4.10882 9.70686 4.04508 9.60384 3.95997 9.52002L1.55264 7.18669C1.45408 7.09125 1.38484 6.96965 1.35307 6.83618C1.3213 6.70272 1.32832 6.56296 1.3733 6.43335C1.41704 6.30385 1.49539 6.18879 1.59986 6.10065C1.70434 6.01251 1.83095 5.95465 1.96597 5.93335L5.2793 5.45269C5.40347 5.43993 5.5224 5.39603 5.62507 5.32505C5.72774 5.25408 5.81083 5.15833 5.86664 5.04669L7.32597 2.05335C7.36037 1.98653 7.40541 1.92574 7.4593 1.87335L7.5193 1.82669C7.55038 1.79242 7.58659 1.76319 7.62664 1.74002L7.6993 1.71335L7.81264 1.66669H8.0933C8.21663 1.67936 8.33485 1.72257 8.43728 1.79241C8.53971 1.86225 8.62312 1.95652 8.67997 2.06669L10.1593 5.04669C10.2119 5.15398 10.2898 5.24695 10.3861 5.31764C10.4825 5.38834 10.5945 5.43468 10.7126 5.45269L14.026 5.93335C14.1632 5.95305 14.2924 6.01011 14.3994 6.09829C14.5064 6.18648 14.5871 6.30239 14.6326 6.43335C14.6754 6.5643 14.68 6.70475 14.6457 6.83818C14.6115 6.97162 14.5399 7.09252 14.4393 7.18669L11.946 9.54669Z"
                fill="#EFB754"
              />
            </svg>
            <span className="mt-[2px]">7:00 {t("pm")}</span>
          </div>
        </div>
        <div className="size-5 min-h-5 min-w-5 overflow-hidden rounded-full md:size-6 md:min-h-6 md:min-w-6">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10.3333" r="10" fill="#2E38BB" />
            <g clip-path="url(#clip0_0_1)">
              <path
                d="M9.99055 15.8976C10.2536 16.1432 8.21055 15.9354 8.27823 15.9198C8.17154 15.8932 8.2531 15.8028 8.31783 15.7744C8.48451 15.6819 8.65237 15.5862 8.8223 15.489C8.92692 15.4255 9.05577 15.3945 9.17281 15.4437C9.21122 15.4583 9.24817 15.4785 9.28393 15.4993C9.51828 15.643 9.75707 15.7633 9.98789 15.8956L9.99055 15.8976Z"
                fill="white"
              />
              <path
                d="M15.3323 10.858C15.314 11.002 15.3004 11.1724 15.2628 11.317C15.2099 11.5075 15.0965 11.5128 14.9375 11.4109C14.8532 11.3603 14.7436 11.3149 14.7025 11.2213C14.6736 11.1446 14.7398 11.0711 14.7938 11.0214C14.8822 10.9415 14.9685 10.863 15.056 10.784C15.1585 10.6781 15.322 10.6857 15.3326 10.8534V10.8578L15.3323 10.858Z"
                fill="white"
              />
              <path
                d="M4.34259 10.0883C4.22053 10.1594 4.07986 10.1981 3.94923 10.2549C3.86944 10.2862 3.78935 10.3172 3.70956 10.3482C3.61794 10.3769 3.51391 10.4448 3.41964 10.422C3.36526 10.407 3.34162 10.3508 3.3428 10.3014C3.32211 9.93818 3.40604 8.23657 3.72256 8.4394C3.8059 8.50173 3.82363 8.62729 3.86382 8.72124C3.89692 8.81197 3.93121 8.90358 3.96726 8.99548C4.02489 9.14357 4.08636 9.29254 4.15167 9.44297C4.21551 9.59019 4.28319 9.73799 4.35441 9.88696C4.38603 9.94989 4.4147 10.0365 4.34613 10.086L4.34259 10.0889V10.0883Z"
                fill="white"
              />
              <path
                d="M9.07115 7.61083C8.75847 7.8119 8.4322 7.99599 8.10978 8.18272C7.97974 8.26437 7.81513 8.32203 7.66795 8.25062C7.57043 8.20935 7.50127 8.11891 7.45103 8.0276C6.95956 7.13026 6.52601 6.18785 6.24969 5.19627C6.22486 5.0274 6.32712 4.87375 6.47518 4.79824C6.82007 4.60858 7.18476 4.45551 7.56659 4.33669C7.83907 4.2404 8.06604 4.36537 8.13874 4.64136C8.40413 5.49187 8.76409 6.31194 9.20532 7.12265C9.29073 7.2924 9.23458 7.50313 9.07528 7.6079L9.07115 7.61083Z"
                fill="white"
              />
              <path
                d="M12.3787 4.86643C12.7508 5.14652 12.2552 5.43861 12.0306 5.60397C11.7392 5.81967 11.4469 6.04181 11.1434 6.24141C10.9286 6.37985 10.6466 6.69301 10.3721 6.66345C10.2601 6.64823 10.1803 6.53145 10.1244 6.43779C9.87056 6.02395 9.6362 5.59489 9.42489 5.15471C9.38411 5.06896 9.35337 4.99433 9.30875 4.90506C9.21713 4.70399 9.05843 4.46547 9.11192 4.2442C9.15566 4.09406 9.36372 4.05982 9.50528 4.06801C10.5142 4.09465 11.5134 4.35103 12.374 4.86292L12.3785 4.86614L12.3787 4.86643Z"
                fill="white"
              />
              <path
                d="M13.8792 11.9559C12.3832 13.3133 11.6878 12.9413 13.7148 13.9674C14.0843 14.1255 14.6529 12.9053 14.8302 12.5948C14.9664 12.3425 14.7217 12.2755 14.5379 12.1804C14.4339 12.128 14.3284 12.0729 14.2246 12.017C14.1185 11.9594 13.9959 11.8792 13.883 11.9535L13.8792 11.9559Z"
                fill="white"
              />
              <path
                d="M3.64722 11.9134C3.75982 12.2509 3.93477 12.665 4.11505 12.9966C4.26843 13.2594 4.40851 13.462 4.57785 13.4438C4.621 13.4362 4.67568 13.411 4.72089 13.3911C5.09652 13.2193 5.47746 13.0554 5.84333 12.864C5.98341 12.7809 5.98371 12.6278 5.88589 12.5108C5.62729 12.1801 5.38466 11.8426 5.16981 11.5054C5.04007 11.2719 4.82049 11.1776 4.57135 11.3009C4.33463 11.4106 4.0985 11.5195 3.8603 11.6249C3.73381 11.6729 3.61707 11.7639 3.64663 11.9096L3.64722 11.9134Z"
                fill="white"
              />
              <path
                d="M14.5048 6.89261C14.24 6.49955 13.5547 5.38066 13.0989 5.6985C12.4151 6.28736 11.7123 6.86071 10.9971 7.41415C10.8771 7.53035 10.9362 7.64976 11.0361 7.75424C11.3361 8.12623 11.863 8.85704 12.1482 8.93957C12.2221 8.96503 12.2856 8.89655 12.3486 8.8503C13.0582 8.30769 13.7518 7.74078 14.4182 7.15104C14.4927 7.09134 14.5494 6.98392 14.5066 6.89671L14.5051 6.8929L14.5048 6.89261Z"
                fill="white"
              />
              <path
                d="M15.3491 9.2141C15.4112 9.63292 15.157 9.98442 14.858 10.2499C14.6319 10.4524 14.3981 10.6391 14.1791 10.8452C13.4926 11.4381 12.7922 12.0208 12.059 12.5573C11.8249 12.7136 11.5471 12.6621 11.3438 12.4827C10.8287 12.0782 10.2391 11.6014 9.79075 11.1299C9.70504 11.0436 9.60574 10.9356 9.48014 10.9195C9.37582 10.9063 9.27504 10.9737 9.18875 11.0263C8.59797 11.3904 7.99952 11.7396 7.39013 12.0703C7.31152 12.1125 7.22759 12.1514 7.13952 12.1669C6.94151 12.2038 6.7367 12.1385 6.59633 11.9942C5.52561 10.7588 4.70284 9.33586 4.11207 7.81483C4.06183 7.69074 4.024 7.56459 4.03701 7.4326C4.08518 6.86627 4.73269 6.23 5.10477 5.82201C5.20023 5.71431 5.37577 5.67948 5.48216 5.79275C5.53447 5.84572 5.5593 5.92182 5.57999 5.99264C5.67722 6.32834 5.791 6.67457 5.92399 7.02666C6.25439 7.87249 6.66903 8.77831 7.22493 9.54804C7.38097 9.7096 7.62153 9.69936 7.80062 9.57907C8.17388 9.354 8.42213 9.19332 8.74781 8.9785C10.2677 7.90849 9.86493 8.37296 11.1115 9.64492C11.2415 9.78365 11.3846 9.93379 11.5515 10.0953C11.6192 10.1606 11.7102 10.2156 11.8069 10.2039C11.8964 10.1949 11.9638 10.1448 12.0265 10.0915C12.5673 9.63292 13.6377 8.69636 14.0925 8.26847C14.1428 8.22165 14.183 8.18945 14.2385 8.13502C14.4005 7.97785 14.626 7.67581 14.8042 7.67932C14.9103 7.68079 14.972 7.80166 15.0063 7.89122C15.1689 8.30945 15.2886 8.75519 15.3485 9.20913L15.3491 9.2141Z"
                fill="white"
              />
              <path
                d="M12.9807 14.8288C12.5434 15.1296 11.8503 15.6119 11.3795 15.633C11.2912 15.6389 11.2052 15.6076 11.1242 15.5642C10.0366 15.0003 8.895 14.275 7.9753 13.3911C7.83374 13.1833 7.94338 12.9589 8.15085 12.8503C8.4718 12.6521 8.7889 12.4493 9.10365 12.2412C9.19585 12.178 9.30697 12.1262 9.42075 12.1458C9.48607 12.1549 9.54665 12.1856 9.60103 12.2263C10.5987 13.061 11.7371 13.7426 12.8853 14.227C13.1341 14.3289 13.1971 14.652 12.9837 14.8252L12.9805 14.8282L12.9807 14.8288Z"
                fill="white"
              />
              <path
                d="M8.06603 15.1788C7.79532 15.3131 7.4658 15.6111 7.17854 15.5935C7.09402 15.5827 7.0358 15.5435 6.95571 15.5101C6.89719 15.4835 6.82952 15.4527 6.75622 15.4182C6.17048 15.1343 5.05041 14.5826 5.76589 14.2001C6.08477 14.0423 6.38888 13.8541 6.71071 13.7043C6.85937 13.6472 7.00093 13.7347 7.10111 13.8404C7.40344 14.1269 7.7108 14.3903 8.02081 14.6332C8.22739 14.772 8.29241 15.0175 8.06987 15.1764L8.06603 15.1791V15.1788Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_0_1">
                <rect
                  width="12.0185"
                  height="11.9496"
                  fill="white"
                  transform="translate(3.34015 4.06714)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <svg
        width="294"
        height="566"
        viewBox="0 0 294 566"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.3164 8.9259H199.609C199.609 19.6815 190.89 28.4007 180.135 28.4007H112.791C102.036 28.4007 93.3164 19.6815 93.3164 8.9259Z"
          fill="#BFBECB"
        />
        <rect
          x="129.111"
          y="17.0404"
          width="34.0808"
          height="3.24579"
          rx="1.6229"
          fill="#A09FB1"
        />
        <ellipse
          cx="166.843"
          cy="18.6633"
          rx="1.21717"
          ry="1.6229"
          fill="#A09FB1"
        />
        <rect
          x="6.08586"
          y="6.08586"
          width="281.566"
          height="553.828"
          rx="40.1667"
          stroke="#BFBECB"
          stroke-width="12.1717"
        />
      </svg>
    </div>
  );
}
