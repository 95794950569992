export default function HeroIllustration({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="571"
      height="577"
      viewBox="0 0 571 577"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M506.499 167.376C502.351 167.376 498.977 170.793 498.977 174.995C498.977 179.197 502.351 182.615 506.499 182.615C510.647 182.615 514.023 179.197 514.023 174.995C514.023 170.793 510.647 167.376 506.499 167.376ZM506.499 185.63C500.709 185.63 496 180.86 496 174.995C496 169.132 500.709 164.36 506.499 164.36C512.289 164.36 517 169.132 517 174.995C517 180.86 512.289 185.63 506.499 185.63Z"
        fill="#F69B7C"
      />
      <ellipse cx="11" cy="295.913" rx="11" ry="10.9978" fill="#FFBD31" />
      <ellipse cx="435.5" cy="338.063" rx="24.5" ry="24.8146" fill="#F7869B" />
      <ellipse cx="274" cy="270.918" rx="162" ry="161.968" fill="#7980CE" />
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M48.5 128.969C48.5 67.3888 98.4203 17.4685 160 17.4685C221.58 17.4685 271.5 67.3888 271.5 128.969V228.924C271.5 235.275 266.351 240.424 260 240.424H60C53.6487 240.424 48.5 235.275 48.5 228.924V128.969Z"
          stroke="#7980CE"
          shape-rendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <path
          d="M68 151.964C68 90.108 118.144 39.9639 180 39.9639C241.856 39.9639 292 90.108 292 151.964V251.919C292 258.547 286.627 263.919 280 263.919H80C73.3726 263.919 68 258.547 68 251.919V151.964Z"
          fill="url(#pattern0_0_1)"
          shape-rendering="crispEdges"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M540.832 346.672C545.874 348.785 549.914 352.877 552.001 357.984C554.087 352.877 558.126 348.785 563.17 346.672C558.126 344.558 554.087 340.467 552.001 335.36C549.914 340.467 545.874 344.558 540.832 346.672ZM553.551 365.916H550.451C550.451 356.171 542.622 348.242 533 348.242V345.102H533.059C542.655 345.069 550.451 337.154 550.451 327.428H553.551C553.551 337.173 561.379 345.102 571 345.102V348.242H570.941C561.347 348.275 553.551 356.189 553.551 365.916Z"
        fill="#F2D8A0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1524 74.7238C31.47 76.1141 34.1278 78.8059 35.5005 82.166C36.8733 78.8059 39.53 76.1141 42.8486 74.7238C39.53 73.3335 36.8733 70.6417 35.5005 67.2816C34.1278 70.6417 31.47 73.3335 28.1524 74.7238ZM36.5204 87.3843H34.4806C34.4806 80.973 29.3303 75.7567 23 75.7567V73.6909H23.0388C29.3517 73.6692 34.4806 68.4622 34.4806 62.0632H36.5204C36.5204 68.4746 41.6707 73.6909 48 73.6909V75.7567H47.9612C41.6493 75.7784 36.5204 80.9854 36.5204 87.3843Z"
        fill="#9DA3D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M140.764 497.151V501.637H139.236V497.151H140.764ZM146.019 495.249L148.162 499.134L146.838 499.933L144.695 496.049L146.019 495.249ZM133.981 495.249L135.305 496.049L133.162 499.933L131.839 499.134L133.981 495.249ZM149.661 490.852L153.373 493.095L152.609 494.48L148.897 492.237L149.661 490.852ZM130.338 490.852L131.102 492.237L127.391 494.48L126.627 493.095L130.338 490.852ZM129.285 485.138V486.737H125V485.138H129.285ZM155 485.138V486.737H150.714V485.138H155ZM127.392 477.396L131.103 479.639L130.339 481.024L126.628 478.781L127.392 477.396ZM152.608 477.396L153.372 478.781L149.661 481.024L148.897 479.639L152.608 477.396ZM146.838 471.942L148.162 472.742L146.019 476.626L144.696 475.827L146.838 471.942ZM133.162 471.942L135.304 475.827L133.981 476.626L131.838 472.742L133.162 471.942ZM140.764 470.239V474.724H139.236V470.239H140.764Z"
        fill="#58CFEF"
      />
      <rect
        width="392"
        height="534.894"
        transform="matrix(-1 0 0 1 520 41.9685)"
        fill="url(#pattern1_0_1)"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="8"
          y="0.968506"
          width="304"
          height="303.956"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_0_1"
          x="28"
          y="23.9639"
          width="304"
          height="303.956"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern0_0_1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_0_1"
            transform="translate(-0.250051) scale(0.000797926)"
          />
        </pattern>
        <pattern
          id="pattern1_0_1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image1_0_1"
            transform="matrix(0.000526316 0 0 0.000387147 -0.3 -0.132791)"
          />
        </pattern>
        <image
          id="image0_0_1"
          width="1880"
          height="1253"
          href="/images/trailing.png"
        />
        <image
          id="image1_0_1"
          width="3657"
          height="2926"
          href="/images/manikan.png"
        />
      </defs>
    </svg>
  );
}
