export default function CustomOrders() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4999 31.6666C24.1818 31.6666 27.1666 28.6818 27.1666 24.9999C27.1666 21.318 24.1818 18.3333 20.4999 18.3333C16.818 18.3333 13.8333 21.318 13.8333 24.9999C13.8333 28.6818 16.818 31.6666 20.4999 31.6666Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8999 25L18.9832 26.0834C19.2999 26.4 19.8166 26.4167 20.1332 26.1L23.0999 23.3667"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5002 36.6666H25.5002C32.2002 36.6666 33.4002 33.9833 33.7502 30.7166L35.0002 20.7166C35.4502 16.6499 34.2835 13.3333 27.1669 13.3333H13.8335C6.71687 13.3333 5.5502 16.6499 6.0002 20.7166L7.2502 30.7166C7.6002 33.9833 8.8002 36.6666 15.5002 36.6666Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 12.7833V11.1667C13 7.41668 16.0167 3.73335 19.7667 3.38335C24.2333 2.95001 28 6.46668 28 10.85V13.15"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
