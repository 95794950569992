import { cn } from "~/utils/helper";
import WhiteDiv from "../howItWorksPage/WhiteDiv";
import { useTranslation } from "react-i18next";

export default function OrderStatus({ className }: { className?: string }) {
  const { t } = useTranslation();
  return (
    <WhiteDiv
      className={cn(
        "flex w-[70%] min-w-[250px] rounded-lg p-2 text-[10px] shadow-lg md:top-[30%] md:text-sm",
        className,
      )}
    >
      <div className="flex flex-1 justify-center  px-[15px] py-[13px]  font-light text-Secondary-Dark-Blue-db-200">
        {t("ongoing")}
      </div>
      <div className="flex flex-1 justify-center  rounded-lg bg-Primary-Magic-Mint-Mm-200 py-[13px] text-Secondary-Dark-Blue-db-500">
        {t("completed")}
      </div>
      <div className="flex flex-1 justify-center  px-[15px] py-[13px]  font-light text-Secondary-Dark-Blue-db-200">
        {t("canceled")}
      </div>
    </WhiteDiv>
  );
}
