import { useState } from "react";
import { useTranslation } from "react-i18next";
import TitleAndHeader from "~/components/TitleAndHeader";
import ChatWithArtisans from "~/svgs/whyUseMakesy/ChatWithArtisans";
import CustomMake from "~/svgs/whyUseMakesy/CustomMake";
import SketchToDress from "~/svgs/whyUseMakesy/SketchToDress";
import TrackYourOrder from "~/svgs/whyUseMakesy/TrackYourOrder";
import { cn } from "~/utils/helper";

export default function WhyUseMakesy() {
  let { t } = useTranslation("home");
  const [selectedInfo, setSelectedInfo] = useState(2);

  const whyUseMakesy = [
    {
      id: 1,
      title: t("customMake"), // Translation key for "Custom Make"
      icon: <CustomMake />,
      description: t("customMakeDescription"), // Translation key for "Not sure what you want?..."
    },
    {
      id: 2,
      title: t("sketchToDress"), // Translation key for "Sketch to dress"
      icon: <SketchToDress />,
      description: t("sketchToDressDescription"), // Translation key for "Not sure what you want?..."
    },
    {
      id: 3,
      title: t("trackYourOrders"), // Translation key for "Track your orders"
      icon: <TrackYourOrder />,
      description: t("trackYourOrdersDescription"), // Translation key for "Not sure what you want?..."
    },
    {
      id: 4,
      title: t("chatWithArtisans"), // Translation key for "Chat with artisans"
      icon: <ChatWithArtisans />,
      description: t("chatWithArtisansDescription"), // Translation key for "Not sure what you want?..."
    },
  ];
  return (
    <div className="cont flex min-w-full flex-col items-stretch gap-5 py-10 lg:-translate-y-36 lg:gap-16 lg:py-0">
      <div className="relative text-center">
        <TitleAndHeader title={t("whyMakesy")} />
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -left-[100%] top-1/2 -translate-y-1/2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4992 3.28774C6.35103 3.28774 2.9768 6.75958 2.9768 11.0277C2.9768 15.2959 6.35103 18.7677 10.4992 18.7677C14.6474 18.7677 18.0232 15.2959 18.0232 11.0277C18.0232 6.75958 14.6474 3.28774 10.4992 3.28774ZM10.4992 21.8306C4.70931 21.8306 0 16.9851 0 11.0277C0 5.07191 4.70931 0.224854 10.4992 0.224854C16.2892 0.224854 21 5.07191 21 11.0277C21 16.9851 16.2892 21.8306 10.4992 21.8306Z"
            fill="#FAECA6"
          />
        </svg>
      </div>
      <div className="flex flex-col gap-10 rounded-2xl border-gray-200 bg-white py-10  text-center lg:max-h-52 lg:flex-row lg:gap-0 lg:border lg:ltr:divide-x lg:rtl:[&>div:not(:last-child)]:border-l ">
        {whyUseMakesy.map((item) => (
          <div
            key={item.title}
            className={cn(
              "group flex flex-1 cursor-pointer flex-col items-center justify-center  gap-5 px-8 transition-[height] max-lg:py-5 ",
              {
                "open relative rounded-xl border !border-l !border-r border-dashed border-Primary-Magic-Mint-Mm-600 bg-Primary-Magic-Mint-Mm-50 shadow-lg shadow-[#EDE5FF] max-lg:!min-h-[270px] lg:top-14 lg:!h-72 lg:-translate-y-1/2":
                  selectedInfo === item.id,
              },
            )}
            onClick={() =>
              setSelectedInfo((id) => (id === item.id ? 0 : item.id))
            }
          >
            <span className="min-h-[60px]">{item.icon}</span>
            <p className="text-xl font-bold text-Secondary-Dark-Blue-db-500">
              {item.title}
            </p>
            <p className="hidden text-lg text-Secondary-Dark-Blue-db-300 group-[.open]:block">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
