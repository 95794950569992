export default function DashedLine({ className }: { className: string }) {
  return (
    <svg
      width="1440"
      height="192"
      viewBox="0 0 1440 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1438.85 52.6835C1386.85 78.6416 1276.15 118.052 1249.33 68.0281C1220.08 13.4681 1275.98 -8.73781 1286.39 28.8447C1296.8 66.4272 1263.35 142.25 1084.1 107.917C904.84 73.5836 813.303 84.2508 775.572 118.322C754.742 137.132 752.783 156.935 751.574 163.12"
        stroke="#545DC4"
        stroke-dasharray="2 2"
      />
      <path
        d="M-203.935 6.93769C-130.347 -3.31973 20.237 -6.89529 33.868 60.8619C48.7352 134.763 -28.1906 139.223 -26.5107 91.9984C-24.8307 44.7733 45.1818 -29.9347 251.372 77.339C457.563 184.613 573.671 206.908 632.914 181.915C665.62 168.117 675.613 146.045 679.466 139.378"
        stroke="#545DC4"
        stroke-dasharray="2 2"
      />
    </svg>

    // <svg
    //   width="688"
    //   height="187"
    //   viewBox="0 0 688 200"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <path
    //     d="M-195.935 6.5697C-122.344 -3.42749 28.2458 -6.91242 41.8774 59.1255C56.7451 131.152 -20.1837 135.499 -18.5037 89.472C-16.8237 43.4452 53.1916 -29.3673 259.39 75.1844C465.589 179.736 581.702 201.465 640.947 177.106C673.654 163.659 683.648 142.147 687.501 135.649"
    //     stroke="#A09FB1"
    //     strokeDasharray="2 2"
    //   />
    // </svg>
  );
}
