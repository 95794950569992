export default function ForArtisans() {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_21_7094)">
        <rect
          x="6.5"
          y="2"
          width="60"
          height="60"
          rx="16"
          fill="url(#paint0_linear_21_7094)"
        />
        <path
          d="M48.1662 25L32.5912 36.6433"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.1662 38.965L32.5912 27.31"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.9164 29.6667C31.1716 29.6667 32.9998 27.8385 32.9998 25.5833C32.9998 23.3282 31.1716 21.5 28.9164 21.5C26.6613 21.5 24.8331 23.3282 24.8331 25.5833C24.8331 27.8385 26.6613 29.6667 28.9164 29.6667Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.9164 42.5C31.1716 42.5 32.9998 40.6718 32.9998 38.4166C32.9998 36.1615 31.1716 34.3333 28.9164 34.3333C26.6613 34.3333 24.8331 36.1615 24.8331 38.4166C24.8331 40.6718 26.6613 42.5 28.9164 42.5Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_21_7094"
          x="0.5"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.803958 0 0 0 0 0.90922 0 0 0 0 0.945833 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_21_7094"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_21_7094"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_21_7094"
          x1="19.4545"
          y1="6.77273"
          x2="52.1818"
          y2="56.5455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCEAF2" />
          <stop offset="1" stop-color="#58CFEF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
