export default function TeamCollaboration() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.497 8.95C22.422 8.9375 22.3345 8.9375 22.2595 8.95C20.5345 8.8875 19.1595 7.475 19.1595 5.725C19.1595 3.9375 20.597 2.5 22.3845 2.5C24.172 2.5 25.6095 3.95 25.6095 5.725C25.597 7.475 24.222 8.8875 22.497 8.95Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2094 18.05C22.9219 18.3375 24.8094 18.0375 26.1344 17.15C27.8969 15.975 27.8969 14.05 26.1344 12.875C24.7969 11.9875 22.8844 11.6875 21.1719 11.9875"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.45938 8.95C7.53438 8.9375 7.62188 8.9375 7.69688 8.95C9.42188 8.8875 10.7969 7.475 10.7969 5.725C10.7969 3.9375 9.35938 2.5 7.57188 2.5C5.78438 2.5 4.34688 3.95 4.34688 5.725C4.35938 7.475 5.73438 8.8875 7.45938 8.95Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.74687 18.05C7.03437 18.3375 5.14687 18.0375 3.82188 17.15C2.05938 15.975 2.05938 14.05 3.82188 12.875C5.15938 11.9875 7.07187 11.6875 8.78437 11.9875"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 18.2875C14.925 18.275 14.8375 18.275 14.7625 18.2875C13.0375 18.225 11.6625 16.8125 11.6625 15.0625C11.6625 13.275 13.1 11.8375 14.8875 11.8375C16.675 11.8375 18.1125 13.2875 18.1125 15.0625C18.1 16.8125 16.725 18.2375 15 18.2875Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3625 22.225C9.59999 23.4 9.59999 25.325 11.3625 26.5C13.3625 27.8375 16.6375 27.8375 18.6375 26.5C20.4 25.325 20.4 23.4 18.6375 22.225C16.65 20.9 13.3625 20.9 11.3625 22.225Z"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
