export default function TrackOrder() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.58751 5.75001C3.58751 1.42501 10.1 1.42501 11.0875 5.75001C11.675 8.28751 10.0625 10.4375 8.66251 11.775C7.63751 12.75 6.02501 12.7375 5.00001 11.775C3.61251 10.4375 2.00001 8.28751 2.58751 5.75001Z"
        stroke="#302E56"
        stroke-width="1.5"
      />
      <path
        d="M18.8375 20.75C19.8375 16.425 26.3875 16.425 27.3875 20.75C27.975 23.2875 26.3625 25.4375 24.95 26.775C23.925 27.75 22.3 27.7375 21.275 26.775C19.8625 25.4375 18.25 23.2875 18.8375 20.75Z"
        stroke="#302E56"
        stroke-width="1.5"
      />
      <path
        d="M15 6.25H18.35C20.6625 6.25 21.7375 9.1125 20 10.6375L10.0125 19.375C8.27499 20.8875 9.34999 23.75 11.65 23.75H15"
        stroke="#302E56"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.85777 6.875H6.87221"
        stroke="#302E56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.1078 21.875H23.1222"
        stroke="#302E56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
