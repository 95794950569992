import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import TitleAndHeader from "~/components/TitleAndHeader";
import SegmentsIllustration from "~/svgs/home/SegmentsIllustration";
import ForArtisans from "~/svgs/howMakesyWorks/ForArtisans";
import ForCustomers from "~/svgs/howMakesyWorks/ForCustomers";
import ForGarmentProducers from "~/svgs/howMakesyWorks/ForGarmentProducers";

export default function Segments() {
  const { t } = useTranslation("home");

  const segments = [
    {
      title: t("forCustomers"),
      description: t("forCustomersDescription"),
      svg: <ForCustomers />,
      to: "designers",
    },
    {
      title: t("forGarmentProducers"),
      description: t("forGarmentProducersDescription"),
      svg: <ForGarmentProducers />,
      to: "workshops",
    },
    {
      title: t("forArtisans"),
      description: t("forArtisansDescription"),
      svg: <ForArtisans />,
      to: "artisans",
    },
  ];
  return (
    <section className="cont relative min-w-full overflow-x-hidden py-[72px]">
      <TitleAndHeader
        title={t("howMakesyWorks")}
        header={t("ourPartners")}
        alignHeader
        className="mb-12"
      />
      <div className="flex flex-col gap-5 lg:flex-row">
        {segments.map((segment, index) => (
          <div
            key={index}
            className="flex flex-1 flex-col  items-center justify-center gap-2 rounded-xl border border-dashed border-Primary-Magic-Mint-Mm-600 px-9 py-6"
          >
            <div className="flex items-center justify-center gap-4">
              {segment.svg}
            </div>
            <h3 className="text-center text-xl font-bold text-Secondary-Dark-Blue-db-500">
              {segment.title}
            </h3>
            <p className="flex-1 text-center text-lg text-Secondary-Dark-Blue-db-300">
              {segment.description}
            </p>
            <Link to={`/segments/${segment.to}`} className="btn mt-3 text-xl">
              {t("learnMore")}
            </Link>
          </div>
        ))}
      </div>
      <SegmentsIllustration className="absolute left-0 top-1/2 -translate-y-1/2" />
    </section>
  );
}
