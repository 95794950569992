import { useLoaderData } from "@remix-run/react";
import TitleAndHeader from "~/components/TitleAndHeader";
import InstantChat from "~/svgs/home/features/InstantChat";
import TeamCollaboration from "~/svgs/home/features/TeamCollaboration";
import TrackOrder from "~/svgs/home/features/TrackOrder";
import { useTranslation } from "react-i18next";
import MainIllustration from "~/svgs/home/features/MainIllustration";
import { loader } from "./route";
import CustomOrders from "~/svgs/home/features/CustomOrders";

interface Feature {
  sideColor: string;
  title: string;
  icon: JSX.Element;
  description: string;
}

export default function Features() {
  const { locale } = useLoaderData<typeof loader>();
  const { t } = useTranslation("home");

  const features: Feature[] = [
    {
      sideColor: " before:bg-[#58CFEF]",
      title: t("instantChat"),
      icon: <CustomOrders />,
      description: t("instantChatDescription"),
    },
    {
      sideColor: " before:bg-[#FF4266]",
      title: t("teamCollaboration"),
      icon: <TeamCollaboration />,
      description: t("teamCollaborationDescription"),
    },
    {
      sideColor: " before:bg-[#FF6533]",
      title: t("trackOrder"),
      icon: <TrackOrder />,
      description: t("trackOrderDescription"),
    },
    {
      sideColor: " before:bg-[#FFBD31]",
      title: t("scheduleCalls"),
      icon: <InstantChat />,
      description: t("scheduleCallsDescription"),
    },
  ];

  return (
    <section className="min-w-full overflow-x-hidden bg-gray-50 py-12">
      <div className="cont min-w-full">
        <TitleAndHeader
          title={t("makesyFeaturesTitle")}
          header={t("makesyFeaturesHeader")}
          alignHeader
          className="mb-10"
        />
        <div className="flex flex-col items-center justify-between gap-10 lg:flex-row lg:gap-[150px]">
          <div className="no-scrollbar relative flex max-w-full grid-cols-2 gap-5 overflow-x-auto px-1 max-lg:justify-start lg:grid">
            {features.map((feature, index) => (
              <FeatureComponent key={index} feature={feature} />
            ))}
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute -right-20 -top-6 max-lg:hidden"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3096 11.9826C10.5305 13.7554 10.5305 17.7817 12.3096 19.5544C14.0665 21.305 17.9341 21.3056 19.6908 19.5548C21.4697 17.782 21.4697 13.7551 19.6908 11.9823C17.9341 10.2315 14.0665 10.2321 12.3096 11.9826ZM17.3653 30.2066C17.2998 30.9245 16.7216 31.511 16.0007 31.511C15.2797 31.511 14.7015 30.9245 14.6359 30.2065C14.0016 23.2593 8.35059 17.7121 1.2837 17.1076C0.576951 17.0471 0 16.4779 0 15.7685V14.5338C0 14.5064 0.0222089 14.4842 0.0496049 14.4842C7.68376 14.4587 13.9652 8.67849 14.636 1.33053C14.7015 0.612546 15.2797 0.026123 16.0007 0.026123C16.7216 0.026123 17.2998 0.612554 17.3654 1.33053C17.9997 8.27776 23.6505 13.8249 30.7163 14.4295C31.423 14.49 32 15.0592 32 15.7685V17.0033C32 17.0307 31.9778 17.0529 31.9504 17.0529C24.3175 17.0784 18.0361 22.8586 17.3653 30.2066Z"
                fill="#FF6533"
              />
            </svg>
          </div>
          <div className="flex flex-1 justify-center">
            <MainIllustration className="" locale={locale} />
          </div>
        </div>
      </div>
    </section>
  );
}

function FeatureComponent({ feature }: { feature: Feature }) {
  return (
    <article
      className={`feature-component relative min-h-[256px] max-w-72 rounded-[48px] border border-Light-grey-Lg-100 bg-white 
      px-8 py-6 before:rounded-[48px] max-lg:min-w-[250px] ${feature.sideColor}
    `}
    >
      <div className="mb-5 p-2">{feature.icon}</div>
      <p className="mb-3 text-xl font-bold text-Secondary-Dark-Blue-db-500">
        {feature.title}
      </p>
      <p className="text-sm font-light text-Secondary-Crayola-Space-Cs-400">
        {feature.description}
      </p>
    </article>
  );
}
