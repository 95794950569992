export default function SegmentsIllustration({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="208"
      height="456"
      viewBox="0 0 208 456"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.2" filter="url(#filter0_f_222_7446)">
        <ellipse cy="227.52" rx="108" ry="127.52" fill="#9DA3D5" />
      </g>
      <defs>
        <filter
          id="filter0_f_222_7446"
          x="-208"
          y="0"
          width="416"
          height="455.041"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="50"
            result="effect1_foregroundBlur_222_7446"
          />
        </filter>
      </defs>
    </svg>
  );
}
