import { useTranslation } from "react-i18next";
import QuestionsList from "~/components/QuestionsList";
import TitleAndHeader from "~/components/TitleAndHeader";
import FaqIllustration from "~/svgs/FaqIllustration";
import { Link, useLoaderData } from "@remix-run/react";
import { loader } from "./route";

export default function Questions() {
  const { t } = useTranslation("home");
  const { featuredFAQs, locale } = useLoaderData<typeof loader>();

  return (
    <section className="cont relative flex min-w-full flex-col gap-4 overflow-hidden pb-9 pt-16 lg:flex-row lg:pt-32">
      <div className="flex-[2] ">
        <TitleAndHeader
          title={t("faq")}
          header={t("questions")}
          className="relative z-10"
          alignHeader
        />
        {!featuredFAQs || !featuredFAQs.length ? (
          <p className="mt-6">{t("no-faqs")}</p>
        ) : null}
      </div>
      {featuredFAQs && featuredFAQs.length ? (
        <div className="relative z-10 flex flex-[3] flex-col items-center rounded-3xl bg-Primary-Magic-Mint-Mm-50 px-4  py-5 ">
          <QuestionsList
            className="self-stretch"
            questions={featuredFAQs}
            locale={locale}
          />
          <div>
            <Link
              className="btn mt-6 bg-Secondary-Crayola-Space-Cs-500"
              to="/faq"
            >
              {t("readMore")}
            </Link>
          </div>
        </div>
      ) : null}
      <FaqIllustration className="absolute left-16 top-56 max-h-full max-w-full scale-125" />
    </section>
  );
}
